import React from "react";
import Layout from '../layouts/index';
import BookImage from "../images/book-sentencePatterns1.png"
import { OutboundLink } from "gatsby-plugin-google-analytics";

class CourseDetails extends React.Component {
  render() {
    return (
      <Layout>
        {/* Course info */}
        <div className="container-fluid bg-white pt-2 pt-md-0">
        <div className="container margin-from-header pt-6 pb-6">
          <div className="row d-flex justify-content-md-center">
          
            <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-center">
              <h2>(Audio) Basic Grammar and Sentence Patterns - Book 2</h2>
              <p>This book series offers a new practical and effective way of learning and communicating in Vietnamese, through essential grammar understanding and sentence pattern practicing.</p>
              {/*<h1 className="text-success">$9.99</h1>*/}
              <div className="row pl-1 mt-2">
              <button class="btn btn-solid-blue">
                <OutboundLink href="https://gum.co/kskA?wanted=true">
                    Free download
                 </OutboundLink>
              </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <img src={BookImage} className="img-fluid float-right rounded" alt="Easy Vietnamese conversation"></img>
            </div>
          </div>
        </div>
        </div>

          {/* Course contents */}
        <div className="container margin-from-header pb-6">
          <div className="row mb-6 justify-content-md-center">
            <div className="col-sm-12 col-md-8">
              <h2>What will you achieve?</h2>
              <ul>
                <li>Learn basic Vietnamese pronunciation through a comprehensive guide with Audio MP3s</li>
                <li>Improve your listening with 40 minutes of Audio MP3s at both slow and natural speeds</li>
                <li>Practice along with a native speaker to improve your pronunciation</li>
              </ul>

              <h2 className="pt-4">Audio Recordings
              </h2>
              <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/894680422&color=%235f68b9&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            </div>
          </div>
        </div>
        
      </Layout>
    );
  }
}

export default CourseDetails;